import { graphql } from "./gql";

// COMPONENTS

export const componentHeroFragment = graphql(/* GraphQL */ `
  fragment componentHero on ComponentHero {
    __typename
    sys {
      id
    }
    internalName
    reverse
    eyebrow
    heading
    headingHighlightColor
    backgroundAccent
    listStyle
    textAlign
    columnWidths
    body {
      json
      links {
        entries {
          block {
            ...componentLogoCloud
            ...componentCustomJsonMeta
          }
        }
      }
    }
    callToActionCollection(limit: 2) {
      items {
        ...cta
      }
    }
    reference {
      ...componentVideo
      ...componentImage
      ...componentSingleInstance
      ...componentCustomJsonMeta
    }
    popup
  }
`);

export const componentConversionPanelFragment = graphql(/* GraphQL */ `
  fragment componentConversionPanel on ComponentConversionPanel {
    __typename
    sys {
      id
    }
    internalName
    variation
    heading
    headingType
    body {
      json
    }
    callToActionCollection(limit: 3) {
      items {
        ...cta
      }
    }
    accentImage {
      ...asset
    }
    logos {
      ...componentLogoCloud
    }
  }
`);

export const componentVideoFragment = graphql(/* GraphQL */ `
  fragment componentVideo on ComponentVideo {
    __typename
    sys {
      id
    }
    internalName
    thumbnail {
      ...asset
    }
    url
    title
    body {
      json
    }
    mediaCollection(limit: 2) {
      items {
        ...asset
      }
    }
  }
`);

export const componentImageFragment = graphql(/* GraphQL */ `
  fragment componentImage on ComponentImage {
    __typename
    sys {
      id
    }
    internalName
    alt
    caption {
      json
    }
    imageDesktop {
      ...asset
    }
  }
`);

export const componentTestimonialFragment = graphql(/* GraphQL */ `
  fragment componentTestimonial on ComponentTestimonial {
    __typename
    sys {
      id
    }
    internalName
    quote
    variant
    showCompany
    showPerson
    person {
      sys {
        id
      }
      name
      role
      headshot {
        ...asset
      }
      company {
        ...entityCompany
      }
    }
  }
`);

export const componentListItemFragment = graphql(/* GraphQL */ `
  fragment componentListItem on ComponentListItem {
    __typename
    sys {
      id
    }
    internalName
    icon {
      ...asset
    }
    heading
    body {
      json
    }
    callToAction {
      ...cta
    }
  }
`);

export const componentSwitchbackFragment = graphql(/* GraphQL */ `
  fragment componentSwitchback on ComponentSwitchback {
    __typename
    sys {
      id
    }
    internalName
    columnWidths
    reverse
    icon {
      __typename
      internalName
      imageDesktop {
        ...asset
      }
      alt
    }
    eyebrow
    eyebrowType
    heading
    body {
      json
      links {
        entries {
          block {
            ...componentTestimonial
            ...componentListItem
          }
        }
      }
    }
    callToActionCollection(limit: 2) {
      items {
        ...cta
      }
    }
    reference {
      ...componentImage
      ...componentHeading
      ...componentCustomJsonMeta
    }
  }
`);

export const componentHeadingFragment = graphql(/* GraphQL */ `
  fragment componentHeading on ComponentHeading {
    __typename
    sys {
      id
    }
    internalName
    alignment
    width
    eyebrow
    eyebrowIcon {
      ...asset
    }
    headingType
    heading
    body {
      json
    }
    callToActionCollection(limit: 3) {
      items {
        ...cta
      }
    }
  }
`);

export const componentStatisticFragment = graphql(/* GraphQL */ `
  fragment componentStatistic on ComponentStatistic {
    __typename
    sys {
      id
    }
    heading
    headingType
    body {
      json
    }
  }
`);

export const componentStatisticPanelFragment = graphql(/* GraphQL */ `
  fragment componentStatisticPanel on ComponentStatisticPanel {
    __typename
    sys {
      id
    }
    statisticCollection(limit: 4) {
      items {
        ...componentStatistic
      }
    }
  }
`);

export const componentAccordionFragment = graphql(/* GraphQL */ `
  fragment componentAccordion on ComponentAccordion {
    __typename
    sys {
      id
    }
    internalName
    heading
    defaultOpen
    body {
      json
    }
  }
`);

export const componentFaqFragment = graphql(/* GraphQL */ `
  fragment componentFaq on ComponentFaq {
    __typename
    sys {
      id
    }
    faqHeading {
      ...componentHeading
    }
    faqCollection(limit: 10) {
      items {
        ...componentAccordion
      }
    }
  }
`);

export const componentLogoCloudFragment = graphql(/* GraphQL */ `
  fragment componentLogoCloud on ComponentLogoCloud {
    __typename
    sys {
      id
    }
    internalName
    heading
    headingType
    companyCollection(limit: 16) {
      items {
        ...entityCompany
      }
    }
  }
`);

export const componentCard = graphql(/* GraphQL */ `
  fragment componentCard on ComponentCard {
    sys {
      id
    }
    __typename
    internalName
    heading
    headingType
    eyebrow
    layout
    spacing
    contained
    textAlign
    link
    icon {
      ...asset
    }
    iconStyle
    image {
      ...asset
    }
    body {
      json
    }
    callToAction {
      ...cta
    }
    teamMember {
      sys {
        id
      }
      name
      role
      headshot {
        ...asset
      }
      twitter
      linkedIn
    }
    isBlurred
  }
`);

export const componentCardDeckFragment = graphql(/* GraphQL */ `
  fragment componentCardDeck on ComponentCardDeck {
    sys {
      id
    }
    __typename
    internalName
    layout
    heading
    body {
      json
    }
    callToAction {
      ...cta
    }
    callToActionPosition
    position
    type
    cardCollection(limit: 7) {
      items {
        ...componentCard
        ...componentVideo
        ...templateBlogPostListing
        ...templateCaseStudyListing
        ...templateIntegrationListing
        ...templateWidgetListing
        ...templateTemplateListing
        ...templateUseCaseListing
      }
    }
  }
`);

export const componentCarouselFragment = graphql(/* GraphQL */ `
  fragment componentCarousel on ComponentCarousel {
    sys {
      id
    }
    __typename
    internalName
    autoplay
    interval
    elementsPerPage
    itemsCollection(limit: 4) {
      items {
        ...componentTestimonial
      }
    }
  }
`);

export const componentCodeSnippedFragment = graphql(/* GraphQL */ `
  fragment componentCodeSnippet on ComponentCodeSnippet {
    sys {
      id
    }
    __typename
    internalName
    language
    code {
      json
    }
  }
`);

export const componentPricingCardFragment = graphql(/* GraphQL */ `
  fragment componentPricingCard on ComponentPricingCard {
    sys {
      id
    }
    __typename
    internalName
    description
    planName
    price
    cadence
    body {
      json
    }
    callToActionCollection(limit: 2) {
      items {
        ...cta
      }
    }
    color
  }
`);

export const componentPricingCardDeckFragment = graphql(/* GraphQL */ `
  fragment componentPricingCardDeck on ComponentPricingCardDeck {
    sys {
      id
    }
    __typename
    internalName
    pricingCardCollection(limit: 3) {
      items {
        ...componentPricingCard
      }
    }
  }
`);

export const componentSingleInstanceFragment = graphql(/* GraphQL */ `
  fragment componentSingleInstance on ComponentSingleInstance {
    sys {
      id
    }
    __typename
    component
    formName
    formHeading
    formCta
    formAccentColor
    resource
    numberOfCards
    resourceHeading
    resourceHeadingType
    headingSizeDesktop
    headingSizeTablet
    headingSizeMobile
    resourceLink {
      ...cta
    }
    body {
      json
    }
  }
`);

export const componentLessonFragment = graphql(/* GraphQL */ `
  fragment componentLesson on ComponentLesson {
    sys {
      id
    }
    __typename
    thumbnail {
      ...asset
    }
    url
    category
  }
`);

export const componentRowFragment = graphql(/* GraphQL */ `
  fragment componentRow on ComponentRow {
    sys {
      id
    }
    __typename
    internalName
    cellCollection(limit: 4) {
      items {
        ...componentCell
      }
    }
  }
`);

export const componentCellFragment = graphql(/* GraphQL */ `
  fragment componentCell on ComponentCell {
    sys {
      id
    }
    __typename
    internalName
    heading
    tooltipText {
      json
    }
    body {
      json
    }
    icon
    callToAction {
      ...cta
    }
    callToActionsCollection {
      items {
        ...cta
      }
    }
  }
`);

export const componentPricingComparisonTableFragment = graphql(/* GraphQL */ `
  fragment componentPricingComparisonTable on ComponentPricingComparisonTable {
    __typename
    sys {
      id
    }
    internalName
    rowCollection {
      items {
        ...componentRow
      }
    }
  }
`);

export const componentRichTextFragment = graphql(/* GraphQL */ `
  fragment componentRichText on ComponentRichText {
    __typename
    sys {
      id
    }
    internalName
    textAlign
    body {
      json
      links {
        entries {
          block {
            ...componentImage
          }
        }
      }
    }
  }
`);

export const componentCustomJsonOnlyFragment = graphql(/* GraphQL */ `
  fragment componentCustomJsonOnly on ComponentCustomJsonOnly {
    __typename
    sys {
      id
    }
    name
    componentId
    json
  }
`);

export const componentCustomJsonFragment = graphql(/* GraphQL */ `
  fragment componentCustomJson on ComponentCustomJson {
    __typename
    sys {
      id
    }
    name
    componentId
    json
    assetReferencesCollection(limit: 10) {
      items {
        ...asset
      }
    }
    componentReferencesCollection(limit: 10) {
      items {
        ...componentHeading
        ...componentCard
        ...componentCardDeck
        ...componentPricingCardDeck
        ...componentPricingComparisonTable
        ...componentCarousel
        ...cta
        ...entityPerson
        ...entityCompany
        ...templateCaseStudyListing
        ...componentCustomJsonOnly
        ...componentTestimonial
      }
    }
  }
`);

export const componentCustomJsonMetaFragment = graphql(/* GraphQL */ `
  fragment componentCustomJsonMeta on ComponentCustomJson {
    __typename
    sys {
      id
    }
    name
    componentId
    json
  }
`);

// TEMPLATE DETAIL

export const templatePageFragment = graphql(/* GraphQL */ `
  fragment templatePage on TemplatePage {
    __typename
    sys {
      id
    }
    internalName
    slug
    seo {
      ...metaSeo
    }
    isLandingPage
    sectionsCollection {
      items {
        ...layoutSection
      }
    }
  }
`);

export const templateSolutionFragment = graphql(/* GraphQL */ `
  fragment templateSolution on TemplateSolution {
    __typename
    sys {
      id
    }
    internalName
    slug
    seo {
      ...metaSeo
    }
    sectionsCollection {
      items {
        ...layoutSection
      }
    }
  }
`);

export const templateBlogPostFragment = graphql(/* GraphQL */ `
  fragment templateBlogPost on TemplateBlogPost {
    sys {
      id
    }
    __typename
    internalName
    title
    slug
    seo {
      ...metaSeo
    }
    author {
      ...entityPerson
    }
    publishDate
    useMarkdown
    video {
      ...componentVideo
    }
    body {
      json
      links {
        entries {
          block {
            ...componentImage
            ...componentVideo
            ...componentCodeSnippet
            ...componentConversionPanel
            ...componentEmbedded
          }
        }
        assets {
          block {
            ...asset
          }
        }
      }
    }
    bodyMd
    category
    tag
    tableOfContents
    integrationsCollection {
      items {
        __typename
        slug
        title
        integrationIcon {
          ...asset
        }
      }
    }
    widgetsCollection {
      items {
        __typename
        slug
        title
        externalLink
        widgetIcon {
          ...asset
        }
      }
    }
    conversionPanel {
      ...componentConversionPanel
    }
    isPreviewContent
    relatedBlogPostsCollection(limit: 3) {
      items {
        ...templateBlogPostListing
      }
    }
  }
`);

export const templateLowCodeFragment = graphql(/* GraphQL */ `
  fragment templateLowCode on TemplateLowCode {
    sys {
      id
    }
    __typename
    internalName
    title
    slug
    seo {
      ...metaSeo
    }
    hideFeaturedImage
    author {
      ...entityPerson
    }
    publishDate
    useMarkdown
    video {
      ...componentVideo
    }
    body {
      json
      links {
        entries {
          block {
            ...componentImage
            ...componentVideo
            ...componentCodeSnippet
            ...componentConversionPanel
          }
        }
        assets {
          block {
            ...asset
          }
        }
      }
    }
    bodyMd
    category
    tag
    tableOfContents
    integrationsCollection {
      items {
        __typename
        slug
        title
        integrationIcon {
          ...asset
        }
      }
    }
    widgetsCollection {
      items {
        __typename
        slug
        title
        externalLink
        widgetIcon {
          ...asset
        }
      }
    }
    conversionPanel {
      ...componentConversionPanel
    }
    isPreviewContent
  }
`);

export const templateCaseStudyFragment = graphql(/* GraphQL */ `
  fragment templateCaseStudy on TemplateCaseStudy {
    sys {
      id
    }
    __typename
    internalName
    title
    slug
    isFeatured
    seo {
      ...metaSeo
    }
    author {
      ...entityPerson
    }
    publishDate
    category
    company {
      ...entityCompany
    }
    industry
    statisticalHighlights {
      ...componentStatisticPanel
    }
    summary {
      json
    }
    body {
      json
      links {
        entries {
          block {
            ...componentStatisticPanel
            ...componentImage
            ...componentVideo
            ...componentCodeSnippet
            ...componentHeading
            ...componentTestimonial
            ...componentConversionPanel
          }
        }
        assets {
          block {
            ...asset
          }
        }
      }
    }
    sectionsCollection {
      items {
        __typename
        sys {
          id
        }
        internalName
        idLink
        backgroundColor
        colorScheme
        paddingTopDesktop
        paddingTopTablet
        paddingTopMobile
        paddingBottomDesktop
        paddingBottomTablet
        paddingBottomMobile
        paddingTop
        paddingBottom
        backgroundImage {
          ...componentImage
        }
        component {
          ...componentConversionPanel
          ...componentSwitchback
          ...componentHeading
          ...componentTestimonial
          ...componentCardDeck
          ...componentStatisticPanel
          ...componentSingleInstance
        }
      }
    }
  }
`);

export const templateTemplateFragment = graphql(/* GraphQL */ `
  fragment templateTemplate on TemplateTemplate {
    sys {
      id
    }
    __typename
    internalName
    title
    slug
    category
    tag
    templateLink
    embedUrl
    templateImage {
      ...asset
    }
    seo {
      ...metaSeo
    }
    integrationsCollection {
      items {
        __typename
        slug
        title
        integrationIcon {
          ...asset
        }
      }
    }
    widgetsCollection {
      items {
        __typename
        slug
        title
        externalLink
        widgetIcon {
          ...asset
        }
      }
    }
    body
  }
`);

export const templateUseCaseFragment = graphql(/* GraphQL */ `
  fragment templateUseCase on TemplateUseCase {
    sys {
      id
    }
    __typename
    internalName
    title
    slug
    featuredImage {
      ...asset
    }
    seo {
      ...metaSeo
    }
    sectionsCollection {
      items {
        ...layoutSection
      }
    }
  }
`);

export const templateEventFragment = graphql(/* GraphQL */ `
  fragment templateEvent on TemplateEvent {
    sys {
      id
      publishedAt
    }
    __typename
    internalName
    slug
    sortDate
    metaSeo {
      ...metaSeo
    }
    sectionsCollection {
      items {
        ...layoutSection
      }
    }
  }
`);

export const templateIntegrationFragment = graphql(/* GraphQL */ `
  fragment templateIntegration on TemplateIntegration {
    __typename
    sys {
      id
    }
    seo {
      ...metaSeo
    }
    internalName
    integrationImage {
      ...asset
    }
    title
    slug
    category
    externalLink
    otherServices
    sectionsCollection {
      items {
        ...layoutSection
      }
    }
  }
`);

// TEMPLATE LISTINGS

export const templateBlogPostListingFragment = graphql(/* GraphQL */ `
  fragment templateBlogPostListing on TemplateBlogPost {
    sys {
      id
    }
    __typename
    internalName
    title
    slug
    excerpt
    seo {
      ...metaSeo
    }
    hideFromListings
    featuredBlog
    author {
      name
      role
      headshot {
        url
      }
    }
    publishDate
    category
    tag
    excerpt
    video {
      ...componentVideo
    }
  }
`);

export const templateLowCodeListingFragment = graphql(/* GraphQL */ `
  fragment templateLowCodeListing on TemplateLowCode {
    sys {
      id
    }
    __typename
    internalName
    title
    slug
    excerpt
    seo {
      ...metaSeo
    }
    hideFromListings
    featuredBlog
    author {
      name
      role
      headshot {
        url
      }
    }
    publishDate
    category
    tag
    excerpt
    video {
      ...componentVideo
    }
  }
`);

export const templateCaseStudyListingFragment = graphql(/* GraphQL */ `
  fragment templateCaseStudyListing on TemplateCaseStudy {
    sys {
      id
    }
    __typename
    internalName
    title
    slug
    seo {
      ...metaSeo
    }
    isFeatured
    author {
      name
      role
      headshot {
        url
      }
    }
    statisticalHighlights {
      ...componentStatisticPanel
    }
    company {
      ...entityCompany
    }
    publishDate
    category
    industry
    summary {
      json
    }
  }
`);

export const templateIntegrationListingFragment = graphql(/* GraphQL */ `
  fragment templateIntegrationListing on TemplateIntegration {
    __typename
    sys {
      id
    }
    seo {
      ...metaSeo
    }
    internalName
    integrationImage {
      ...asset
    }
    title
    slug
    category
    externalLink
    otherServices
  }
`);

export const templateWidgetListingFragment = graphql(/* GraphQL */ `
  fragment templateWidgetListing on TemplateWidget {
    __typename
    sys {
      id
    }
    internalName
    title
    slug
    externalLink
    widgetImage {
      ...asset
    }
    widgetIcon {
      ...asset
    }
  }
`);

export const templateTemplateListingFragment = graphql(/* GraphQL */ `
  fragment templateTemplateListing on TemplateTemplate {
    sys {
      id
    }
    __typename
    internalName
    title
    excerpt
    slug
    category
    tag
    templateLink
    templateImage {
      ...asset
    }
  }
`);

export const templateUseCaseListingFragment = graphql(/* GraphQL */ `
  fragment templateUseCaseListing on TemplateUseCase {
    sys {
      id
    }
    __typename
    internalName
    title
    slug
    featuredImage {
      ...asset
    }
    seo {
      ...metaSeo
    }
  }
`);

export const templateEventListingFragment = graphql(/* GraphQL */ `
  fragment templateEventListing on TemplateEvent {
    sys {
      id
      publishedAt
    }
    __typename
    internalName
    slug
    sortDate
    metaSeo {
      ...metaSeo
    }
  }
`);

// LAYOUT SECTION

export const layoutSectionFragment = graphql(/* GraphQL */ `
  fragment layoutSection on LayoutSection {
    __typename
    sys {
      id
    }
    internalName
    idLink
    backgroundColor
    colorScheme
    paddingTopDesktop
    paddingTopTablet
    paddingTopMobile
    paddingBottomDesktop
    paddingBottomTablet
    paddingBottomMobile
    paddingTop
    paddingBottom
    backgroundImage {
      ...componentImage
    }
    component {
      ...layoutSectionComponent
    }
  }
`);

export const layoutSectionComponentFragment = graphql(/* GraphQL */ `
  fragment layoutSectionComponent on LayoutSectionComponent {
    ...componentHero
    ...componentConversionPanel
    ...componentSwitchback
    ...componentHeading
    ...componentTestimonial
    ...componentLogoCloud
    ...componentCardDeck
    ...componentFaq
    ...componentRichText
    ...componentVideo
    ...componentImage
    ...componentCustomJsonMeta
    ...componentSingleInstance
    ...componentStatisticPanel
  }
`);

// OTHER

export const assetFragment = graphql(/* GraphQL */ `
  fragment asset on Asset {
    __typename
    sys {
      id
    }
    title
    description
    url
    width
    height
  }
`);

export const metaSeoFragment = graphql(/* GraphQL */ `
  fragment metaSeo on MetaSeo {
    __typename
    sys {
      id
    }
    pageTitle
    canonicalUrl
    pageDescription
    openGraphImage {
      ...asset
    }
    noIndex
    noFollow
  }
`);

export const ctaFragment = graphql(/* GraphQL */ `
  fragment cta on ComponentCallToAction {
    __typename
    sys {
      id
    }
    internalName
    label
    link
    openInNewWindow
    hierarchy
    size
    startIcon
    endIcon
    variant
    showModal
    modalHeading
    modalDescription
    ctaList
    modalForm
  }
`);

export const menuItemFragment = graphql(/* GraphQL */ `
  fragment menuItem on ElementMenu {
    sys {
      id
    }
    internalName
    icon {
      ...asset
    }
    iconAccent
    heading
    subhead
    link
    menuTitle
    menuCollection(limit: 7) {
      items {
        sys {
          id
        }
        internalName
        icon {
          ...asset
        }
        iconAccent
        heading
        subhead
        link
      }
    }
    menuIiTitle
    menuIiCollection(limit: 7) {
      items {
        sys {
          id
        }
        internalName
        icon {
          ...asset
        }
        iconAccent
        heading
        subhead
        link
      }
    }
    featuredContent
    featuredImageCollection {
      items {
        ...asset
      }
    }
  }
`);

export const entityPersonFragment = graphql(/* GraphQL */ `
  fragment entityPerson on EntityPerson {
    sys {
      id
    }
    __typename
    name
    role
    twitter
    linkedIn
    headshot {
      ...asset
    }
    company {
      ...entityCompany
    }
  }
`);

export const componentBanner = graphql(/* GraphQL */ `
  fragment componentBanner on ComponentBanner {
    sys {
      id
    }
    internalName
    iconImage {
      ...asset
    }
    body {
      json
    }
    callToAction {
      ...cta
    }
  }
`);

export const entityCompanyFragment = graphql(/* GraphQL */ `
  fragment entityCompany on EntityCompany {
    sys {
      id
    }
    __typename
    companyName
    website
    relation
    shortDescription
    industries
    employees
    region
    logoIcon {
      ...asset
    }
    logoOnDark {
      ...asset
    }
    logoOnLight {
      ...asset
    }
  }
`);

export const componentEmbeddedFragment = graphql(/* GraphQL */ `
  fragment componentEmbedded on Embedded {
    __typename
    sys {
      id
    }
    internalName
    iframeUrl
  }
`);
