import mixpanel from "mixpanel-browser";

let mixpanelAnalytics: typeof mixpanel | null = null;

if (typeof window !== 'undefined') {
  try {
    const MIXPANEL_TOKEN = process.env.NEXT_PUBLIC_MIXPANEL_TOKEN;

    if (!MIXPANEL_TOKEN) {
      console.warn('Mixpanel token is not set. Analytics will not be tracked.');
    } else {
      mixpanel.init(MIXPANEL_TOKEN, { debug: process.env.NODE_ENV !== "production" });
      mixpanelAnalytics = mixpanel;
    }
  } catch (error) {
    console.error('Failed to initialize Mixpanel:', error);
  }
}

export { mixpanelAnalytics };