"use client";
import { Dialog, Transition } from "@headlessui/react";
import { useState, useEffect, Fragment } from "react";
import CtaLink from "components/CtaLink";
import Image from "next/image";
import BasicRegistrationForm from "components/ComponentCustomJSON/BasicRegistrationForm";

interface PopupCTAProps {
  label: string;
  extendClassNames?: string;
  modalHeading?: string | null;
  modalDescription?: string | null;
  variant?: string;
  size?: string;
  ctaList?: {
    [key: string]: string;
  };
  modalForm?: {
    formId: string;
    formLabel: string;
    successMessage: string;
    submitText: string;
    chilipiper: boolean;
  };
}

const PopupCTA: React.FC<PopupCTAProps> = ({
  label,
  variant,
  size,
  modalHeading,
  modalDescription,
  ctaList,
  modalForm,
}) => {
  const [mounted, setMounted] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  if (!mounted) {
    return null;
  }

  return (
    <>
      <CtaLink
        label={label}
        onClick={openModal}
        variant={variant}
        size={size}
        textAlign="center"
        extendClassNames="w-full"
      />
      <Transition show={isOpen} as={Fragment}>
        <Dialog onClose={closeModal} className="relative z-50">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-primary-dark-700/50" aria-hidden="true" />
          </Transition.Child>

          <div className="fixed inset-0 flex items-center justify-center p-4">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="relative mx-auto max-w-3xl rounded-lg bg-primary-neutral-50 p-6 shadow-lg">
                <button
                  onClick={closeModal}
                  className="text-gray-400 hover:text-gray-600 absolute right-2 top-2"
                  aria-label="Close"
                >
                  <Image src="/assets/icons/e-remove-glyph-24.svg" alt="Close" width={24} height={24} />
                </button>
                {modalHeading && <h2 className="text-3xl font-bold">{modalHeading}</h2>}
                {modalDescription && <p className="py-3">{modalDescription}</p>}
                {modalForm && (
                  <div className="mt-5">
                    <BasicRegistrationForm json={modalForm} />
                  </div>
                )}
                {ctaList && !modalForm && (
                  <div className="mt-4 flex flex-col gap-2 md:flex-row">
                    {ctaList.label && (
                      <CtaLink
                        link={ctaList.url || ""}
                        label={ctaList.label}
                        onClick={closeModal}
                        variant={variant}
                        textAlign="center"
                        extendClassNames={`max-w-full w-full ${ctaList.url === "" || ctaList.url === "#" ? "opacity-50 cursor-not-allowed pointer-events-none" : ""}`}
                      />
                    )}
                    {ctaList.label2 && (
                      <CtaLink
                        link={ctaList.url2 || ""}
                        label={ctaList.label2}
                        onClick={closeModal}
                        variant={variant}
                        textAlign="center"
                        extendClassNames={`max-w-full w-full ${ctaList.url2 === "" || ctaList.url2 === "#" ? "opacity-50 cursor-not-allowed pointer-events-none" : ""}`}
                      />
                    )}
                  </div>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default PopupCTA;
